import {useState, useEffect, useRef} from 'react'
import {useField} from 'formik'
import {useTranslation} from 'react-i18next'
import cx from 'classnames'

import {FieldWrapper, Icons} from 'src/components'

import styles from './select.module.scss'

import {IField} from 'src/interfaces'
import {getName,  isValid} from "all-iso-language-codes";

const Select = (props: IField) => {
    const {name, options, i18nName, placeholder, onChange} = props

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [otherValue, setOtherValue] = useState<string | null>(null)

    const selectRef = useRef(null)

    useEffect(() => {
        if (selectRef.current) {
            const clickEventHandler = (event: MouseEvent | TouchEvent) => {
                const specifiedElement = selectRef.current
                if (specifiedElement && event.target instanceof Node) {
                    const isClickInside = (specifiedElement as HTMLDivElement).contains(
                        event.target,
                    )
                    if (!isClickInside) {
                        setIsOpen(false)
                    }
                }
            }

            const keyDownEventHandler = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    setIsOpen(false)
                }
            }

            window.addEventListener('mousedown', clickEventHandler, false)
            window.addEventListener('touchstart', clickEventHandler, false)
            window.addEventListener('keydown', keyDownEventHandler, false)

            return () => {
                window.removeEventListener('mousedown', clickEventHandler, false)
                window.removeEventListener('touchstart', clickEventHandler, false)
                window.removeEventListener('keydown', keyDownEventHandler, false)
            }
        }
    }, [selectRef])

    const {t} = useTranslation()

    const [field, meta, {setValue}] = useField({name})

    const handleChange = (value: string) => {
        setValue(value)
        setOtherValue(null)
        if (onChange) {
            onChange(value)
        }
    }

    const currentOption = options?.find((o) => o.value === field.value)

    return < >
        <FieldWrapper {...props}>
            <div className={styles.otherOptionContainer}>
                <div
                    className={cx(
                        styles.wrapper,
                        meta.error && meta.touched && styles.error,
                        !((currentOption && currentOption?.value === 'other') || !!otherValue)?null:styles.otherFullContainer
                    )}
                    ref={selectRef}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div>
                        {currentOption?.label==='Other'||!!otherValue?'Other':
                            (!!currentOption?.label)?(isValid(currentOption?.label||''))?getName(currentOption?.label):t(`options.${i18nName || name}.${currentOption?.label}`,currentOption?.label):
                                t(`placeholder.${placeholder}`,placeholder)}</div>

                    <div className={cx(styles.arrow, isOpen && styles.open)}>
                        <Icons.MiniArrow/>
                    </div>

                    {isOpen ? <div className={styles.basicOptions}>
                        {options?.map((o) => (
                            <div key={o.value} onClick={() => handleChange(o.value)}>
                                {(name==='preferredLanguage')?(o.label==='other'||!isValid(o.label))?o.label:capitalize(getName(o.label)||''):t(`options.${i18nName || name}.${o.label}`)}
                            </div>
                        ))}
                    </div> : null}
                </div>


                {(currentOption && currentOption?.value === 'other') || !!otherValue ?
                    < >
                        <input
                            className={cx(
                                styles.basicInput,
                                styles.internalBasicInput,
                                meta.error && meta.touched && styles.error,
                            )}
                            placeholder={placeholder}
                            onChange={(v)=>{
                                setOtherValue(v.target.value)
                                setValue(v.target.value)
                                if (onChange) {
                                    onChange(v.target.value)
                                }
                            }}
                        />
                    </>
                    :null}
            </div>
        </FieldWrapper>
    </>
}
function capitalize(s:string)
{
    return s && s[0].toUpperCase() + s.slice(1);
}
export default Select
