import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './other-input.module.scss'

interface OtherInputProps {
  onAdd: (value: string) => void
}

const OtherInput = (props: OtherInputProps) => {
  const { onAdd } = props

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [value, setValue] = useState<string>('')

  const handleChange = (newValue: string) => {
    if (/^[a-zA-Z\s\-]*$/.test(newValue)) {
      setValue(newValue)
    }
  }

  const handleAddOption = (value: string) => {
    if (value) {
      onAdd(value)
      setValue('')
    }
  }

  const handleClickButton = () => {
    if (value) {
      handleAddOption(value)
    }

    setIsOpen(!isOpen)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setIsOpen(false)
      setValue('')
    }

    if (event.key === 'Enter') {
      handleClickButton()
    }
  }

  return (
    <div className={styles.wrapper}>
      {isOpen || true ? (
        <input
          value={value}
          placeholder={t('placeholder.other')}
          onChange={(event) => handleChange(event.target.value)}
          onKeyDown={handleKeyDown}
        />
      ) : null}
      {!!value?
        <div
            className={cx(styles.button)}
            onClick={handleClickButton}
        >
          {<Icons.Check/>}
        </div>:null}
    </div>
  )
}

export default OtherInput
