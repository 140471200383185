export default {
    label: {
        preferredLanguage: '选择语言',
        email: '电子邮件',
        emailСonfirm: '重新输入您的电子邮件',
        firstName: '名',
        lastName: '姓',
        carCompanies: '您为哪些应用工作？',
        gender: '性别',
        phoneNumber: '电话号码',
        dateOfBirth: '出生日期',
        streetAddress: '街道地址',
        apartment: '公寓、单元、西装或楼层#',
        city: '城市',
        stateSelectHome: '状态',
        zipCode: '邮政编码',
        driversLicenseNumber: '驾驶执照 ＃',
        driversLicenseNumberСonfirm: '重新输入驾驶执照#',
        driversId: 'ID ＃',
        driversIdСonfirm: '重新输入 ID #',
        tlcNumber: '薄层色谱#',
        tlcNumberСonfirm: '重新输入 TLC #',
        howYouDeliver: '你如何交付',
        whatStateDoYouDriveIn: '你在什么州开车',
       "Enter Language":"输入语言",
        "Enter gender":"输入性别"
    },
    placeholder: {
        email: '输入你的电子邮箱',
        emailСonfirm: '重新输入您的电子邮件',
        firstName: '输入你的名字',
        lastName: '输入您的姓氏',
        gender: '选择性别',
        phoneNumber: '000-000-0000',
        streetAddress: '输入您的街道地址',
        apartment: '进入 ＃',
        city: '进入城市',
        stateSelectHome: '选择州',
        zipCode: '输入您的邮政编码',
        driversLicenseNumber: '输入您的驾驶执照',
        driversLicenseNumberСonfirm: '重新输入驾驶执照#',
        driversId: '输入您的身份证件',
        driversIdСonfirm: '重新输入 ID #',
        tlcNumber: '输入您的 TLC #',
        tlcNumberСonfirm: '重新输入 TLC #',
        other: '其他',
        "Enter Language": "輸入語言"
    },
    options: {
        preferredLanguage: {
            en: 'English',
            es: 'Español',
            chinese: '中国人',
            Fre: 'Français',
            ru: 'Русский',
            pa: 'ਪੰਜਾਬੀ',
            Hindi: 'हिन्दी',
            bn: 'বাংলা',
            ur: 'اردو',
            Arabic: 'عربي',
            am: 'Amharic',
            so: 'Somali',
            vi: 'Vietnamese',
            om: 'Oromo',
            other: 'Other',
        },
        carCompanies: {
            uber: 'Uber',
            uberEats: 'Uber Eats',
            lyft: 'Lyft',
            doordash: 'Doordash',
            grubhub: 'Grubhub',
            postmates: 'Postmates',
            instacart: 'Instacart',
            seamless: 'Seamless',
            taxi: 'Taxi',
            'flat-rate': 'Flat-Rate',
        },
        gender: {Other: '其他', other: '其他', Male: '男性', Female: '女性', 'Prefer not to say': '宁愿不说'},
        state: {
            'New York': 'New York',
            'New Jersey': 'New Jersey',
            Pennsylvania: 'Pennsylvania',
            Alabama: 'Alabama',
            Alaska: 'Alaska',
            Arizona: 'Arizona',
            Arkansas: 'Arkansas',
            California: 'California',
            Connecticut: 'Connecticut',
            Colorado: 'Colorado',
            Delaware: 'Delaware',
            'District Of Columbia': 'District Of Columbia',
            Florida: 'Florida',
            Georgia: 'Georgia',
            Hawaii: 'Hawaii',
            Idaho: 'Idaho',
            Illinois: 'Illinois',
            Indiana: 'Indiana',
            Iowa: 'Iowa',
            Kansas: 'Kansas',
            Kentucky: 'Kentucky',
            Louisiana: 'Louisiana',
            Maine: 'Maine',
            Maryland: 'Maryland',
            Massachusetts: 'Massachusetts',
            Michigan: 'Michigan',
            Minnesota: 'Minnesota',
            Mississippi: 'Mississippi',
            Missouri: 'Missouri',
            Montana: 'Montana',
            Nebraska: 'Nebraska',
            Nevada: 'Nevada',
            'New Hampshire': 'New Hampshire',
            'New Mexico': 'New Mexico',
            'North Carolina': 'North Carolina',
            'North Dakota': 'North Dakota',
            Ohio: 'Ohio',
            Oklahoma: 'Oklahoma',
            Oregon: 'Oregon',
            'Rhode Island': 'Rhode Island',
            'South Carolina': 'South Carolina',
            'South Dakota': 'South Dakota',
            Tennessee: 'Tennessee',
            Texas: 'Texas',
            Utah: 'Utah',
            Vermont: 'Vermont',
            Virginia: 'Virginia',
            Washington: 'Washington',
            'West Virginia': 'West Virginia',
            Wisconsin: 'Wisconsin',
            Wyoming: 'Wyoming',
        },
        howYouDeliver: {car: '车', bike: '自行车', onFoot: '徒步'},
    },
    button: {nextStep: '下一步', submit: '提交'},
    error: {
        required: '必须在场',
        email: '必须是电子邮件',
        phone: '无效的电话号码',
        date: '失效日期',
        driverAge: {dateOfBirth: '您必须在 16 至 100 岁之间'},
        name: '必须是有效名称',
        streetAddress: '必须是有效的街道地址',
        city: '必须是有效城市',
        zipCode: '必须是有效的邮政编码',
        driverLicense: '必须是有效的驾驶执照',
        atLeast1: '至少选择一个选项',
        zipCodeMatchState: '邮政编码必须与州匹配',
        equalTo: {
            driversLicenseNumberСonfirm: '驾驶执照必须匹配',
            driversIdСonfirm: 'ID 必须匹配',
            tlcNumberСonfirm: 'TLC 必须匹配',
            emailСonfirm: 'Your email address does not match the entry above. Please try again.',
        },
    },
    other: {
        step: '步',
        oneMoreStep: '更进一步',
        toAccessTheBenefits: '获得利益',
        DUIsHereForYou:
            'DU 为您服务！ 加入我们，为应用程序工作者的权利而战！\n请注意，送货员还没有任何福利。',
        byCompletingTheInformation:
            '填写上述信息并点击“提交”，即表示您确认已阅读、理解并同意我们的<termsOfUseLink>使用条款</termsOfUseLink>。此外，您同意我们可以按照我们的<privacyPolicyLink>隐私政策</privacyPolicyLink>中的规定使用和披露您的信息',
        goodJob: '好工作！',
        submitAndGetBenefits: '提交表格以获得福利！',
        congratulations: '恭喜',
        weWillBeInTouchSoon: '我们会很快地和你联系！',
        successThanks: '感谢您加入我们！',
        successMesage: '请检查您的收件箱以获取欢迎电子邮件',
        useBenefits: '使用您的福利',
        contactUs: '联系我们',
    },
}
