import { useState, useEffect } from 'react'
import { useField } from 'formik'
import cx from 'classnames'
import dayjs from 'dayjs'

import { FieldWrapper } from 'src/components'

import styles from './date-input.module.scss'

import { IField } from 'src/interfaces'

const getInitialValue = (fieldValue: string) => {
  if (fieldValue) {
    const date = dayjs(fieldValue, 'YYYY-mm-DD')

    return {
      month: String(date.month() + 1),
      day: String(date.date()),
      year: String(date.year()),
    }
  } else {
    return {
      month: '',
      day: '',
      year: '',
    }
  }
}

const DateInput = (props: IField) => {
  const { name } = props

  const [{ value: fieldValue }, meta, { setValue: setFieldValue }] = useField({
    name,
  })

  const [value, setValue] = useState<{
    month: string
    day: string
    year: string
  }>(getInitialValue(fieldValue))
  const [strictError, setStrictError] = useState<string[]>([])

  useEffect(() => {
    const { month, day, year } = value

    const valueForFormik = `${year}-${month}-${day}`

    if (valueForFormik !== '--') {
      setFieldValue(valueForFormik)
    }
  }, [value])

  const checkingForStrictErrors = (name: string, value: string) => {
    const removeStrictErrorItem = (name: string) => {
      const findIndex = strictError.findIndex((e) => e === name)
      if (findIndex > -1) {
        const newErrorValue = strictError.slice()
        newErrorValue.splice(findIndex, 1)
        setStrictError(newErrorValue)
      }
    }

    if (name === 'month' || name === 'day') {
      const max = name === 'month' ? 12 : 31
      if (value.length === 2 && (Number(value) > max || value === '00')) {
        setStrictError([...strictError, name])
      } else {
        removeStrictErrorItem(name)
      }
    }

    if (name === 'year') {
      const currentYear = dayjs().year()
      if (
        false
      ) {
        setStrictError([...strictError, name])
      } else {
        removeStrictErrorItem(name)
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: currentValue } = event.currentTarget

    const filteredValue = currentValue.replace(/[^0-9]/g, '')

    checkingForStrictErrors(name, currentValue)

    setValue({
      ...value,
      [name]: filteredValue,
    })
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value: currentValue } = event.currentTarget

    if (currentValue.length === 1 && currentValue !== '0') {
      setValue({
        ...value,
        [name]: `0${currentValue}`,
      })
    }
  }

  const strictErrorName = (() => {
    if (!strictError.length) {
      return undefined
    }

    if (strictError.length === 1 && strictError.includes('year')) {
      return `driverAge.${name}`
    }

    return 'date'
  })()

  return (
    <FieldWrapper {...props} strictError={strictErrorName}>
      <div className={styles.wrapper}>
        <input
          className={cx(
            styles.input,
            ((meta.error && meta.touched) || strictError.includes('month')) &&
              styles.error,
          )}
          name="month"
          placeholder="MM"
          maxLength={2}
          value={value.month}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <input
          className={cx(
            styles.input,
            ((meta.error && meta.touched) || strictError.includes('day')) &&
              styles.error,
          )}
          name="day"
          placeholder="DD"
          maxLength={2}
          value={value.day}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <input
          className={cx(
            styles.input,
            ((meta.error && meta.touched) || strictError.includes('year')) &&
              styles.error,
          )}
          name="year"
          placeholder="YYYY"
          maxLength={4}
          value={value.year}
          onChange={handleChange}
        />
      </div>
    </FieldWrapper>
  )
}

export default DateInput
