export default {
    label: {
        preferredLanguage: 'ቋንቋ ይምረጡ',
        email: 'ኢሜይል',
        emailСonfirm: 'ኢሜልዎን እንደገና ያስገቡ',
        firstName: 'የመጀመሪያ ስም',
        lastName: 'የአያት ስም',
        carCompanies: 'በየትኛው መተግበሪያ(ዎች) ላይ ነው የምትሰራው?',
        gender: 'ጾታ',
        phoneNumber: 'ስልክ ቁጥር',
        dateOfBirth: 'የተወለደበት ቀን',
        streetAddress: 'የአድራሻ ጎዳና',
        apartment: 'ተስማሚ፣ ክፍል፣ ልብስ ወይም ወለል #',
        city: 'ከተማ',
        stateSelectHome: 'ግዛት',
        zipCode: 'አካባቢያዊ መለያ ቁጥር',
        driversLicenseNumber: "የመንጃ ፍቃድ #",
        whatStateDoYouDriveIn: 'በምን አይነት ግዛት ነው የሚነዱት',
        haveHealthInsurance: 'በአሁኑ ጊዜ የጤና መድን አለህ?',
        "Enter Language":"ቋንቋ አስገባ",
        "Enter gender":"ጾታ አስገባ"
    },
    placeholder: {
        email: 'የእርስዎን ኢሜይል ያስገቡ',
        emailСonfirm: 'ኢሜልዎን እንደገና ያስገቡ',
        firstName: 'ስምህን አስገባ',
        lastName: 'የአያት ስምዎን ያስገቡ',
        gender: 'ጾታን ይምረጡ',
        phoneNumber: '000-000-0000',
        streetAddress: 'የመንገድ አድራሻዎን ያስገቡ',
        apartment: 'አስገባ #',
        city: 'ከተማ ግባ',
        stateSelectHome: 'ግዛት ይምረጡ',
        zipCode: 'ዚፕ ኮድዎን ያስገቡ',
        driversLicenseNumber: "የመንጃ ፍቃድ አስገባ",
        driversId: 'መታወቂያዎን ያስገቡ',
        other: 'ሌላ',
        "Enter Language":"ቋንቋ አስገባ",
        "Enter gender":"ጾታ አስገባ"
    },
    options: {
        preferredLanguage: {
            en: 'English',
            es: 'Español',
            chinese: '中国人',
            Fre: 'Français',
            ru: 'Русский',
            pa: 'ਪੰਜਾਬੀ',
            Hindi: 'हिन्दी',
            bn: 'বাংলা',
            ur: 'اردو',
            Arabic: 'عربي',
            am: 'አማርኛ',
            so: 'Somali',
            vi: 'Vietnamese',
            om: 'Oromo',
            other: 'Other',
        },
        carCompanies: {
            uber: 'Uber',
            uberEats: 'Uber Eats',
            lyft: 'Lyft',
            doordash: 'Doordash',
            grubhub: 'Grubhub',
            postmates: 'Postmates',
            instacart: 'Instacart',
            seamless: 'Seamless',
            taxi: 'Taxi',
            'flat-rate': 'Flat-Rate',
        },
        gender: {
            Male: 'ወንድ',
            Female: 'ሴት',
            Other: 'ሌላ',
            other: 'ሌላ',
            'Prefer not to say': 'ላለመናገር እመርጣለሁ።',
        },
        state: {
            'New York': 'New York',
            'New Jersey': 'New Jersey',
            Pennsylvania: 'Pennsylvania',
            Alabama: 'Alabama',
            Alaska: 'Alaska',
            Arizona: 'Arizona',
            Arkansas: 'Arkansas',
            California: 'California',
            Connecticut: 'Connecticut',
            Colorado: 'Colorado',
            Delaware: 'Delaware',
            'District Of Columbia': 'District Of Columbia',
            Florida: 'Florida',
            Georgia: 'Georgia',
            Hawaii: 'Hawaii',
            Idaho: 'Idaho',
            Illinois: 'Illinois',
            Indiana: 'Indiana',
            Iowa: 'Iowa',
            Kansas: 'Kansas',
            Kentucky: 'Kentucky',
            Louisiana: 'Louisiana',
            Maine: 'Maine',
            Maryland: 'Maryland',
            Massachusetts: 'Massachusetts',
            Michigan: 'Michigan',
            Minnesota: 'Minnesota',
            Mississippi: 'Mississippi',
            Missouri: 'Missouri',
            Montana: 'Montana',
            Nebraska: 'Nebraska',
            Nevada: 'Nevada',
            'New Hampshire': 'New Hampshire',
            'New Mexico': 'New Mexico',
            'North Carolina': 'North Carolina',
            'North Dakota': 'North Dakota',
            Ohio: 'Ohio',
            Oklahoma: 'Oklahoma',
            Oregon: 'Oregon',
            'Rhode Island': 'Rhode Island',
            'South Carolina': 'South Carolina',
            'South Dakota': 'South Dakota',
            Tennessee: 'Tennessee',
            Texas: 'Texas',
            Utah: 'Utah',
            Vermont: 'Vermont',
            Virginia: 'Virginia',
            Washington: 'Washington',
            'West Virginia': 'West Virginia',
            Wisconsin: 'Wisconsin',
            Wyoming: 'Wyoming',
        },

    },
    button: { nextStep: 'ቀጣይ እርምጃ', submit: 'አስገባ' },
    error: {
        required: 'መገኘት አለበት።',
        email: 'ኢሜይል መሆን አለበት።',
        phone: 'ልክ ያልሆነ ስልክ ቁጥር',
        date: 'ልክ ያልሆነ ቀን',
        driverAge: { dateOfBirth: 'ዕድሜው ከ16 እስከ 100 ዓመት መሆን አለበት' },
        name: 'ትክክለኛ ስም መሆን አለበት።',
        streetAddress: 'ትክክለኛ የመንገድ አድራሻ መሆን አለበት።',
        city: 'ትክክለኛ ከተማ መሆን አለበት።',
        zipCode: 'የሚሰራ ዚፕ ኮድ መሆን አለበት።',
        driverLicense: 'የሚሰራ መንጃ ፍቃድ መሆን አለበት።',
        atLeast1: 'ቢያንስ አንዱን ይምረጡ',
        zipCodeMatchState: 'ዚፕ ኮድ ከግዛቱ ጋር መዛመድ አለበት።',
        equalTo: {
            emailСonfirm: 'Your email address does not match the entry above. Please try again.',
        },
    },
    other: {
        step: 'ደረጃ',
        oneMoreStep: 'One more step',
        toAccessTheBenefits: 'ጥቅሞቹን ለማግኘት',
        DUIsHereForYou:
            'DU ለእርስዎ እዚህ አለ! ለመተግበሪያ ሰራተኛ መብት ለመታገል ይቀላቀሉን! \nእስካሁን ድረስ ለማድረስ ሰራተኞች ምንም ጥቅማጥቅሞች እንደሌሉ ልብ ይበሉ።',
        byCompletingTheInformation:
            'ከላይ ያለውን መረጃ በመሙላት እና "አስገባ" ን ጠቅ በማድረግ በአገልግሎት ውላችን እንዳነበቡ፣ እንደተረዱ እና እንደተስማሙ ያረጋግጣሉ። እንዲሁም፣ በግላዊነት መመሪያችን ላይ በተገለጸው መሰረት የእርስዎን መረጃ ልንጠቀምበት እና ልንገልጽ እንደምንችል ተስማምተሃል',
        goodJob: 'ጥሩ ስራ!',
        submitAndGetBenefits: 'ጥቅሞቹን ለማግኘት ቅጹን ያስገቡ!',
        congratulations: 'እንኳን ደስ አላችሁ',
        weWillBeInTouchSoon: 'በቅርቡ እናነጋግርዎታለን!',
        successThanks: 'ስለተቀላቀሉ እናመሰግናለን',
        successMesage: 'እባክዎን የእንኳን ደህና መጣችሁ ኢሜይል ለማግኘት የገቢ መልእክት ሳጥንዎን ያረጋግጡ',
        useBenefits: 'ጥቅማ ጥቅሞችዎን ይጠቀሙ',
        contactUs: 'አግኙን',
    },
}
