export { default as getDependingCheck } from './getDependingCheck'

export const getUrlParamsValues = () => ({
  utm:
    new URLSearchParams(document.location.search).get('utm_source') || 'WS_DU',
  contracts: (() => {
    const result = []
    const urlContract = new URLSearchParams(document.location.search).get(
      'contract',
    )
    if (urlContract) {
      result.push(urlContract)
    }
    return result.length > 0 ? result : ['00000108']
  })(),
})
