export default {
    label: {
        preferredLanguage: 'Dooro Luuqad',
        email: 'iimaylka',
        emailСonfirm: 'Dib u geli iimaylkaga',
        firstName: 'Magaca hore',
        lastName: 'Magaca Dambe',
        carCompanies: 'Appkee(yada) aad ka shaqeyso?',
        gender: 'Jinsiga',
        phoneNumber: 'Lambarka taleefanka',
        dateOfBirth: 'Taariikhda Dhalashada',
        streetAddress: 'Cinwaanka Waddada',
        apartment: 'Apt., cutub, suudh, ama dabaqa #',
        city: 'Magaalada',
        stateSelectHome: 'Gobol',
        zipCode: 'Koodhka sibka',
        driversLicenseNumber: "Shatiga darawalnimada #",
        whatStateDoYouDriveIn: 'Gobolkee ku wadaa',
        haveHealthInsurance: 'Hadda ma leedahay caymis caafimaad?',
        "Enter Language": "Geli Luqadda",
        "Enter gender": "Geli jinsiga"
    },
    placeholder: {
        email: 'Geli iimaylkaga',
        emailСonfirm: 'Dib u geli iimaylkaga',
        firstName: 'Gali Magacaaga',
        lastName: 'Geli Magacaaga Dambe',
        gender: 'Dooro Jinsiga',
        phoneNumber: '000-000-0000',
        streetAddress: 'Geli Ciwaanka Waddadaada',
        apartment: 'Geli #',
        city: 'Magaalada gal',
        stateSelectHome: 'Dooro Gobolka',
        zipCode: 'Geli Koodhkaaga Zip-ka',
        driversLicenseNumber: "Geli shatiga darawalnimada",
        driversId: 'Geli aqoonsigaga',
        other: 'Wax kale',
        "Enter Language": "Geli Luqadda",
        "Enter gender": "Geli jinsiga"
    },
    options: {
        preferredLanguage: {
            en: 'English',
            es: 'Español',
            chinese: '中国人',
            Fre: 'Français',
            ru: 'Русский',
            pa: 'ਪੰਜਾਬੀ',
            Hindi: 'हिन्दी',
            bn: 'বাংলা',
            ur: 'اردو',
            Arabic: 'عربي',
            am: 'Amharic',
            so: 'Soomaali',
            vi: 'Vietnamese',
            om: 'Oromo',

            Other: 'Other',
        },
        carCompanies: {
            uber: 'Uber',
            uberEats: 'Uber Eats',
            lyft: 'Lyft',
            doordash: 'Doordash',
            grubhub: 'Grubhub',
            postmates: 'Postmates',
            instacart: 'Instacart',
            seamless: 'Seamless',
            taxi: 'Taxi',
            'flat-rate': 'Flat-Rate',
        },
        gender: {
            Male: 'Lab',
            Female: 'Dheddig',
            Other: 'Kale', other: 'Kale',
            'Prefer not to say': 'Doorbida in aan la odhan',
        },
        state: {
            'New York': 'New York',
            'New Jersey': 'New Jersey',
            Connecticut: 'Connecticut',
            Pennsylvania: 'Pennsylvania',
            Alabama: 'Alabama',
            Alaska: 'Alaska',
            Arizona: 'Arizona',
            Arkansas: 'Arkansas',
            California: 'California',
            Colorado: 'Colorado',
            Delaware: 'Delaware',
            'District Of Columbia': 'District Of Columbia',
            Florida: 'Florida',
            Georgia: 'Georgia',
            Hawaii: 'Hawaii',
            Idaho: 'Idaho',
            Illinois: 'Illinois',
            Indiana: 'Indiana',
            Iowa: 'Iowa',
            Kansas: 'Kansas',
            Kentucky: 'Kentucky',
            Louisiana: 'Louisiana',
            Maine: 'Maine',
            Maryland: 'Maryland',
            Massachusetts: 'Massachusetts',
            Michigan: 'Michigan',
            Minnesota: 'Minnesota',
            Mississippi: 'Mississippi',
            Missouri: 'Missouri',
            Montana: 'Montana',
            Nebraska: 'Nebraska',
            Nevada: 'Nevada',
            'New Hampshire': 'New Hampshire',
            'New Mexico': 'New Mexico',
            'North Carolina': 'North Carolina',
            'North Dakota': 'North Dakota',
            Ohio: 'Ohio',
            Oklahoma: 'Oklahoma',
            Oregon: 'Oregon',
            'Rhode Island': 'Rhode Island',
            'South Carolina': 'South Carolina',
            'South Dakota': 'South Dakota',
            Tennessee: 'Tennessee',
            Texas: 'Texas',
            Utah: 'Utah',
            Vermont: 'Vermont',
            Virginia: 'Virginia',
            Washington: 'Washington',
            'West Virginia': 'West Virginia',
            Wisconsin: 'Wisconsin',
            Wyoming: 'Wyoming',
        },

    },
    button: {nextStep: 'Talaabada Xigta', submit: 'Soo gudbi'},
    error: {
        required: 'waa in uu joogo',
        email: 'Waa inuu ahaado iimayl',
        phone: 'Lambarka taleefanka aan sax ahayn',
        date: 'Invalid date',
        driverAge: {dateOfBirth: 'Waa inuu u dhexeeyaa 16 iyo 100 sano'},
        name: 'Waa inuu ahaadaa magac sax ah',
        streetAddress: 'Waa in uu ahaado cinwaan waddo sax ah',
        city: 'Waa inay noqotaa magaalo ansax ah',
        zipCode: 'Waa inuu noqdaa summada sibka oo sax ah',
        driverLicense: 'Waa inuu ahaado liisan darawalnimo oo sax ah',
        zipCodeMatchState: 'zip koodka waa in uu la mid yahay gobolka',
        atLeast1: 'Dooro ugu yaraan mid',
        equalTo: {emailСonfirm: 'Your email address does not match the entry above. Please try again.',},
    },
    other: {
        step: 'Tallaabo',
        oneMoreStep: 'Hal tallaabo oo kale',
        toAccessTheBenefits: 'si ay u helaan faa\'iidooyinka',
        DUIsHereForYou:
            'DU waa kuu halkan! Nagu soo biir si aan ugu dagaallano xuquuqda shaqaalaha abka! \nOgsoonow in aanay wali jirin wax faa\'iidooyin ah oo loogu talagalay shaqaalaha geynta.',
        byCompletingTheInformation:
            ' Markaad buuxiso macluumaadka kor ku xusan oo guji "Submit", waxaad xaqiijinaysaa inaad akhriday, fahantay, oo aad ogolaatay in lagugu xidho Shuruudaha Adeegsiga. Sidoo kale, waxaad ogolaatay inaan isticmaalno oo aan siidayno macluumaadkaaga sida lagu qeexay Siyaasaddayada Qarsoonnimada',
        goodJob: 'Shaqo wanaagsan!',
        submitAndGetBenefits: 'Soo gudbi foomka si aad u hesho faa\'iidooyinka!',
        congratulations: 'Hambalyo',
        weWillBeInTouchSoon: 'Dhawaan ayaan la xiriiri doonnaa!',
        successThanks: 'Waad ku mahadsan tahay ku soo biirista',
        successMesage: 'Fadlan ka hubi sanduuqaaga fariinta soo dhawaynta',
        useBenefits: 'Isticmaal Faa\'iidooyinkaaga',
        contactUs: 'Nala soo xiriir',
    },
}
