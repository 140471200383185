export default {
  label: {
    preferredLanguage: 'زبان کا انتخاب کریں۔',
    email: 'ای میل',
    firstName: 'پہلا نام',
    lastName: 'آخری نام',
    carCompanies: 'آپ کن ایپلیکیشنز کے لیے کام کرتے ہیں؟',
    gender: 'صنف',
    phoneNumber: 'فون نمبر',
    dateOfBirth: 'پیدائش کی تاریخ',
    streetAddress: 'گلی کا پتہ',
    apartment: 'اپارٹمنٹ، یونٹ، سوٹ، یا فرش #',
    city: 'شہر',
    stateSelectHome: 'حالت',
    zipCode: 'زپ کوڈ',
    driversLicenseNumber: 'ڈرائیور کا لائسنس #',
    driversLicenseNumberСonfirm: 'ڈرائیور کا لائسنس دوبارہ درج کریں #',
    driversId: 'ID',
    driversIdСonfirm: 'ID دوبارہ درج کریں #',
    tlcNumber: 'TLC',
    tlcNumberСonfirm: 'TLC # دوبارہ درج کریں',
    howYouDeliver: 'آپ کیسے ڈیلیور کرتے ہیں۔',
    whatStateDoYouDriveIn: 'آپ کس حالت میں گاڑی چلاتے ہیں؟',

    "Enter gender":""
  },
  placeholder: {
    email: 'اپنا ای میل درج کریں۔',
    emailСonfirm: 'اپنا ای میل دوبارہ درج کریں۔',
    firstName: 'اپنا نام درج کریں',
    lastName: 'اپنا آخری نام درج کریں۔',
    gender: 'صنف منتخب کریں۔',
    phoneNumber: '000-000-0000',
    streetAddress: 'اپنی گلی کا پتہ درج کریں۔',
    apartment: 'درج کریں #',
    city: 'شہر میں داخل ہوں۔',
    stateSelectHome: 'ریاست منتخب کریں۔',
    zipCode: 'اپنا زپ کوڈ درج کریں۔',
    driversLicenseNumber: 'اپنا ڈرائیور کا لائسنس درج کریں۔',
    driversLicenseNumberСonfirm: 'ڈرائیور کا لائسنس دوبارہ درج کریں #',
    driversId: 'اپنی شناخت درج کریں۔',
    driversIdСonfirm: 'ID دوبارہ درج کریں #',
    tlcNumber: 'اپنا TLC # درج کریں',
    tlcNumberСonfirm: 'TLC # دوبارہ درج کریں',
    other: 'دیگر',

  "Enter Language":"زبان درج کریں۔"
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',

      Other: 'Other',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
      taxi: 'Taxi',
      'flat-rate': 'Flat-Rate',
    },
    gender: {
      Male: 'مرد',
      Female: 'عورت',
      Other: 'Other', other: 'Other',
      'Prefer not to say': 'نہ کہنے کو ترجیح دیں۔',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'گاڑی', bike: 'موٹر سائیکل', onFoot: 'پیدل' },
  },
  button: { nextStep: 'اگلا قدم', submit: 'جمع کرائیں' },
  error: {
    required: 'حاضر ہونا ضروری ہے۔',
    email: 'ای میل ہونا ضروری ہے۔',
    phone: 'غلط فون نمبر',
    date: 'غلط تاریخ',
    driverAge: {
      dateOfBirth: 'آپ کی عمر 16 اور 100 سال کے درمیان ہونی چاہیے۔',
    },
    name: 'ایک درست نام ہونا چاہیے۔',
    streetAddress: 'سڑک کا درست پتہ ہونا چاہیے۔',
    city: 'ایک درست شہر ہونا چاہیے۔',
    zipCode: 'ایک درست زپ کوڈ ہونا چاہیے۔',
    driverLicense: 'ایک درست ڈرائیور لائسنس ہونا ضروری ہے۔',
    atLeast1: 'کم از کم ایک آپشن منتخب کریں۔',
    zipCodeMatchState: 'زپ کوڈ ریاست سے مماثل ہونا چاہیے۔',
    equalTo: {
      driversLicenseNumberСonfirm: 'ڈرائیور کا لائسنس مماثل ہونا چاہیے۔',
      driversIdСonfirm: 'D مماثل ہونا ضروری ہے۔',
      tlcNumberСonfirm: 'TLC مماثل ہونا ضروری ہے۔',
      emailСonfirm: 'آپ کا ای میل ایڈریس اوپر دیے گئے اندراج سے میل نہیں کھاتا۔ دوبارہ کوشش کریں.',
    },
  },
  other: {
    step: 'قدم',
    oneMoreStep: 'ایک قدم اور',
    toAccessTheBenefits: 'فوائد تک رسائی حاصل کرنے کے لیے',
    DUIsHereForYou:
      'DU آپ کے لئے یہاں ہے! ایپ ورکرز کے حقوق کے لیے لڑنے کے لیے ہمارے ساتھ شامل ہوں!\nنوٹ کریں کہ ڈیلیوری ورکرز کے لیے ابھی تک کوئی فوائد نہیں ہیں۔',
    byCompletingTheInformation:
      'اوپر دی گئی معلومات کو مکمل کرکے اور "جمع کروائیں" پر کلک کرکے، آپ تصدیق کرتے ہیں کہ آپ نے ہماری <termsOfUseLink> استعمال کی شرائط</termsOfUseLink> کو پڑھا، سمجھ لیا اور ان سے اتفاق کیا۔ اس کے علاوہ، آپ نے اتفاق کیا ہے کہ ہم آپ کی معلومات کو استعمال اور افشاء کر سکتے ہیں جیسا کہ ہماری <privacyPolicyLink>رازداری کی پالیسی</privacyPolicyLink> میں بیان کیا گیا ہے۔',
    goodJob: 'بہت اعلی!',
    submitAndGetBenefits: 'فوائد تک رسائی حاصل کرنے کے لیے فارم جمع کروائیں!',
    congratulations: 'مبارک ہو',
    weWillBeInTouchSoon: 'ہم جلد ہی رابطے میں ہوں گے!',
    successThanks: 'ہمارے ساتھ شامل ہونے کے لیے آپ کا شکریہ!',
    successMesage: 'ہمیں آپ کی معلومات مل گئی ہیں۔ ہم جلد ہی رابطے میں ہوں گے!',
    useBenefits: 'اپنے فوائد کا استعمال کریں۔',
    contactUs: 'ہم سے رابطہ کریں۔',
  },
}
