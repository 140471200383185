export default {
  label: {
    preferredLanguage: 'ਭਾਸ਼ਾ ਚੁਣੋ',
    email: 'ਈ - ਮੇਲ',
    emailСonfirm: 'ਆਪਣੀ ਈਮੇਲ ਦੁਬਾਰਾ ਦਰਜ ਕਰੋ',
    firstName: 'ਪਹਿਲਾ ਨਾਂ',
    lastName: 'ਆਖਰੀ ਨਾਂਮ',
    carCompanies: 'ਤੁਸੀਂ ਕਿਹੜੀਆਂ ਐਪਲੀਕੇਸ਼ਨਾਂ ਲਈ ਕੰਮ ਕਰਦੇ ਹੋ?',
    gender: 'ਲਿੰਗ',
    phoneNumber: 'ਫੋਨ ਨੰਬਰ',
    dateOfBirth: 'ਜਨਮ ਤਾਰੀਖ',
    streetAddress: 'ਸੜਕ ਦਾ ਪਤਾ',
    apartment: 'ਅਪਾਰਟਮੈਂਟ, ਯੂਨਿਟ, ਸੂਟ, ਜਾਂ ਫਰਸ਼ #',
    city: 'ਸ਼ਹਿਰ',
    stateSelectHome: 'ਰਾਜ',
    zipCode: 'ਜ਼ਿਪ ਕੋਡ',
    driversLicenseNumber: 'ਡਰਾਇਵਰ ਦਾ ਲਾਇਸੈਂਸ #',
    driversLicenseNumberСonfirm: 'ਡ੍ਰਾਈਵਰਜ਼ ਲਾਇਸੈਂਸ ਮੁੜ-ਦਾਖਲ ਕਰੋ #',
    driversId: 'ID #',
    driversIdСonfirm: 'ID # ਮੁੜ-ਦਾਖਲ ਕਰੋ',
    tlcNumber: 'TLC #',
    tlcNumberСonfirm: 'TLC # ਮੁੜ-ਦਾਖਲ ਕਰੋ',
    howYouDeliver: 'ਤੁਸੀਂ ਕਿਵੇਂ ਪ੍ਰਦਾਨ ਕਰਦੇ ਹੋ',
    whatStateDoYouDriveIn: 'ਤੁਸੀਂ ਕਿਸ ਰਾਜ ਵਿੱਚ ਗੱਡੀ ਚਲਾਉਂਦੇ ਹੋ',
    "Enter Language":"ਭਾਸ਼ਾ ਦਰਜ ਕਰੋ",
    "Enter gender":"ਲਿੰਗ ਦਾਖਲ ਕਰੋ"
  },
  placeholder: {
    email: 'ਆਪਣਾ ਈ - ਮੇਲ ਭਰੋ',
    firstName: 'ਆਪਣਾ ਨਾਮ ਦਰਜ ਕਰੋ',
    lastName: 'ਆਪਣਾ ਆਖਰੀ ਨਾਮ ਦਰਜ ਕਰੋ',
    gender: 'ਲਿੰਗ ਚੁਣੋ',
    phoneNumber: '000-000-0000',
    streetAddress: 'ਆਪਣਾ ਗਲੀ ਦਾ ਪਤਾ ਦਰਜ ਕਰੋ',
    emailСonfirm: 'ਆਪਣੀ ਈਮੇਲ ਦੁਬਾਰਾ ਦਰਜ ਕਰੋ',
    apartment: '# ਦਾਖਲ ਕਰੋ',
    city: 'ਸ਼ਹਿਰ ਵਿੱਚ ਦਾਖਲ ਹੋਵੋ',
    stateSelectHome: 'ਰਾਜ ਚੁਣੋ',
    zipCode: 'ਆਪਣਾ ਜ਼ਿਪ ਕੋਡ ਦਰਜ ਕਰੋ',
    driversLicenseNumber: 'ਆਪਣਾ ਡਰਾਈਵਰ ਲਾਇਸੰਸ ਦਰਜ ਕਰੋ',
    driversLicenseNumberСonfirm: 'ਡ੍ਰਾਈਵਰਜ਼ ਲਾਇਸੈਂਸ ਮੁੜ-ਦਾਖਲ ਕਰੋ #',
    driversId: 'ਆਪਣੀ ID ਦਰਜ ਕਰੋ',
    driversIdСonfirm: 'ID # ਮੁੜ-ਦਾਖਲ ਕਰੋ',
    tlcNumber: 'ਆਪਣਾ TLC # ਦਾਖਲ ਕਰੋ',
    tlcNumberСonfirm: 'TLC # ਮੁੜ-ਦਾਖਲ ਕਰੋ',
    other: 'ਹੋਰ',
    "Enter Language":"ਭਾਸ਼ਾ ਦਰਜ ਕਰੋ",
    "Enter gender":"ਲਿੰਗ ਦਾਖਲ ਕਰੋ"
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',

      other: 'Other',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
      taxi: 'Taxi',
      'flat-rate': 'Flat-Rate',
    },
    gender: {
      Male: 'ਨਰ',
      Female: 'ਔਰਤ',
      Other: 'ਹੋਰ', other: 'ਹੋਰ',
      'Prefer not to say': 'ਨਾ ਕਹਿਣਾ ਪਸੰਦ ਕਰਦੇ ਹਨ',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'ਕਾਰ', bike: 'ਬਾਈਕ', onFoot: 'ਪੈਦਲ' },
  },
  button: { nextStep: 'ਅਗਲਾ ਕਦਮ', submit: 'ਜਮ੍ਹਾਂ ਕਰੋ' },
  error: {
    required: 'ਹਾਜ਼ਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    email: 'ਈਮੇਲ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    phone: 'ਅਵੈਧ ਫ਼ੋਨ ਨੰਬਰ',
    date: 'ਅਵੈਧ ਮਿਤੀ',
    driverAge: {
      dateOfBirth: 'ਤੁਹਾਡੀ ਉਮਰ 16 ਤੋਂ 100 ਸਾਲ ਦੇ ਵਿਚਕਾਰ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ',
    },
    name: 'ਇੱਕ ਵੈਧ ਨਾਮ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    streetAddress: 'ਇੱਕ ਵੈਧ ਗਲੀ ਦਾ ਪਤਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    city: 'ਇੱਕ ਵੈਧ ਸ਼ਹਿਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    zipCode: 'ਇੱਕ ਵੈਧ ਜ਼ਿਪ ਕੋਡ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    driverLicense: 'ਇੱਕ ਵੈਧ ਡਰਾਈਵਰ ਲਾਇਸੰਸ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    atLeast1: 'ਘੱਟੋ-ਘੱਟ ਇੱਕ ਵਿਕਲਪ ਚੁਣੋ',
    zipCodeMatchState: 'ਜ਼ਿਪ ਕੋਡ ਰਾਜ ਨਾਲ ਮੇਲ ਖਾਂਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    equalTo: {
      driversLicenseNumberСonfirm: 'ਡਰਾਈਵਰ ਲਾਇਸੰਸ ਮੇਲ ਖਾਂਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
      driversIdСonfirm: 'ਆਈਡੀ ਮੇਲ ਖਾਂਦੀ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ',
      tlcNumberСonfirm: 'TLC ਮੇਲ ਖਾਂਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
      emailСonfirm: 'ਤੁਹਾਡਾ ਈਮੇਲ ਪਤਾ ਉਪਰੋਕਤ ਐਂਟਰੀ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ। ਮੁੜ ਕੋਸ਼ਿਸ ਕਰੋ ਜੀ.',
    },
  },
  other: {
    step: 'ਕਦਮ',
    oneMoreStep: 'ਇੱਕ ਹੋਰ ਕਦਮ',
    toAccessTheBenefits: 'ਲਾਭਾਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨ ਲਈ',
    DUIsHereForYou:
      'DU ਤੁਹਾਡੇ ਲਈ ਇੱਥੇ ਹੈ! ਐਪ ਵਰਕਰ ਅਧਿਕਾਰਾਂ ਲਈ ਲੜਨ ਲਈ ਸਾਡੇ ਨਾਲ ਜੁੜੋ!\nਨੋਟ ਕਰੋ ਕਿ ਡਿਲੀਵਰੀ ਕਰਮਚਾਰੀਆਂ ਲਈ ਅਜੇ ਕੋਈ ਲਾਭ ਨਹੀਂ ਹਨ।',
    byCompletingTheInformation:
      'ਉਪਰੋਕਤ ਜਾਣਕਾਰੀ ਨੂੰ ਪੂਰਾ ਕਰਕੇ ਅਤੇ "ਸਬਮਿਟ" \'ਤੇ ਕਲਿੱਕ ਕਰਕੇ, ਤੁਸੀਂ ਪੁਸ਼ਟੀ ਕਰਦੇ ਹੋ ਕਿ ਤੁਸੀਂ ਸਾਡੀਆਂ <termsOfUseLink> ਵਰਤੋਂ ਦੀਆਂ ਸ਼ਰਤਾਂ </termsOfUseLink> ਨੂੰ ਪੜ੍ਹਿਆ, ਸਮਝ ਲਿਆ ਅਤੇ ਸਹਿਮਤ ਹੋ। ਇਸ ਤੋਂ ਇਲਾਵਾ, ਤੁਸੀਂ ਸਹਿਮਤੀ ਦਿੱਤੀ ਹੈ ਕਿ ਅਸੀਂ ਸਾਡੀ <privacyPolicyLink>ਗੋਪਨੀਯਤਾ ਨੀਤੀ</privacyPolicyLink> ਵਿੱਚ ਦਰਸਾਏ ਅਨੁਸਾਰ ਤੁਹਾਡੀ ਜਾਣਕਾਰੀ ਦੀ ਵਰਤੋਂ ਅਤੇ ਖੁਲਾਸਾ ਕਰ ਸਕਦੇ ਹਾਂ।',
    goodJob: 'ਅੱਛਾ ਕੰਮ!',
    submitAndGetBenefits: 'ਲਾਭਾਂ ਤੱਕ ਪਹੁੰਚ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਫਾਰਮ ਜਮ੍ਹਾਂ ਕਰੋ!',
    congratulations: 'ਵਧਾਈਆਂ',
    weWillBeInTouchSoon: 'ਅਸੀਂ ਜਲਦੀ ਹੀ ਸੰਪਰਕ ਵਿੱਚ ਰਹਾਂਗੇ!',
    successThanks: 'ਸਾਡੇ ਨਾਲ ਜੁੜਨ ਲਈ ਤੁਹਾਡਾ ਧੰਨਵਾਦ!',
    successMesage:
      'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਸੁਆਗਤ ਈਮੇਲ ਲਈ ਆਪਣੇ ਇਨਬਾਕਸ ਦੀ ਜਾਂਚ ਕਰੋ!',
    useBenefits: 'ਆਪਣੇ ਲਾਭਾਂ ਦੀ ਵਰਤੋਂ ਕਰੋ',
    contactUs: 'ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ',
  },
}
