export default {
    label: {
        preferredLanguage: 'Afaan filadhu',
        email: 'Imeelii',
        emailСonfirm: 'Irra Deebiin Email Keessan Galchi',
        firstName: 'Maqaa jalqabaa',
        lastName: 'Maqaa Dhumaa',
        carCompanies: 'App(s) kam irratti hojjettu?',
        gender: 'Koorniyaa',
        phoneNumber: 'Lakkoofsa Bilbilaa',
        dateOfBirth: 'Guyyaa Dhalootaa',
        streetAddress: 'Street Address',
        apartment: 'Apt., yuunitii, suutii, ykn lafa # .',
        city: 'Magaalaa',
        stateSelectHome: 'Ibsuu',
        zipCode: 'Koodii Ziip',
        driversLicenseNumber: "Hayyama Konkolaachisummaa ",
        whatStateDoYouDriveIn: 'State akkamii keessa konkolaachifta',
        haveHealthInsurance: 'Yeroo ammaa kana inshuraansii fayyaa qabduu?',

        "Enter gender":""
    },
    placeholder: {
        email: 'Email Keessan Galchaa',
        emailСonfirm: 'Irra Deebiin Email Keessan Galchi',
        firstName: 'Maqaa Keessan Galchi',
        lastName: 'Maqaa Dhumaa Keessan Galchi',
        gender: 'Saala filadhu',
        phoneNumber: '000-000-0000',
        streetAddress: 'Teessoo Daandii Keessan Galchaa',
        apartment: 'Seenuu #',
        city: 'magaalattii barreessi',
        stateSelectHome: 'Select State',
        zipCode: 'Zip Code Keessan Galchaa',
        driversLicenseNumber: "Hayyama Konkolaachisummaa Keessan Galchaa",
        driversId: 'ID Keessan Galchi',
        other: 'Kan biraa',

    "Enter Language":"Afaan Galchi"
    },
    options: {
        preferredLanguage: {
            en: 'English',
            es: 'Español',
            chinese: '中国人',
            Fre: 'Français',
            ru: 'Русский',
            pa: 'ਪੰਜਾਬੀ',
            Hindi: 'हिन्दी',
            bn: 'বাংলা',
            ur: 'اردو',
            Arabic: 'عربي',
            am: 'Amharic',
            so: 'Somali',
            vi: 'Vietnamese',
            om: 'Afaan Oromoo',
            Other: 'Other',
        },
        carCompanies: {
            uber: 'Uber',
            uberEats: 'Uber Eats',
            lyft: 'Lyft',
            doordash: 'Doordash',
            grubhub: 'Grubhub',
            postmates: 'Postmates',
            instacart: 'Instacart',
            seamless: 'Seamless',
            taxi: 'Taxi',
            'flat-rate': 'Flat-Rate',
        },
        gender: {
            Male: 'Dhiira',
            Female: 'Dhalaa',
            Other: 'Other', other: 'Other',
            'Prefer not to say': 'Jechuu dhiisuu filadhu',
        },
        state: {
            'New York': 'New York',
            'New Jersey': 'New Jersey',
            Connecticut: 'Connecticut',
            Pennsylvania: 'Pennsylvania',
            Alabama: 'Alabama',
            Alaska: 'Alaska',
            Arizona: 'Arizona',
            Arkansas: 'Arkansas',
            California: 'California',
            Colorado: 'Colorado',
            Delaware: 'Delaware',
            'District Of Columbia': 'District Of Columbia',
            Florida: 'Florida',
            Georgia: 'Georgia',
            Hawaii: 'Hawaii',
            Idaho: 'Idaho',
            Illinois: 'Illinois',
            Indiana: 'Indiana',
            Iowa: 'Iowa',
            Kansas: 'Kansas',
            Kentucky: 'Kentucky',
            Louisiana: 'Louisiana',
            Maine: 'Maine',
            Maryland: 'Maryland',
            Massachusetts: 'Massachusetts',
            Michigan: 'Michigan',
            Minnesota: 'Minnesota',
            Mississippi: 'Mississippi',
            Missouri: 'Missouri',
            Montana: 'Montana',
            Nebraska: 'Nebraska',
            Nevada: 'Nevada',
            'New Hampshire': 'New Hampshire',
            'New Mexico': 'New Mexico',
            'North Carolina': 'North Carolina',
            'North Dakota': 'North Dakota',
            Ohio: 'Ohio',
            Oklahoma: 'Oklahoma',
            Oregon: 'Oregon',
            'Rhode Island': 'Rhode Island',
            'South Carolina': 'South Carolina',
            'South Dakota': 'South Dakota',
            Tennessee: 'Tennessee',
            Texas: 'Texas',
            Utah: 'Utah',
            Vermont: 'Vermont',
            Virginia: 'Virginia',
            Washington: 'Washington',
            'West Virginia': 'West Virginia',
            Wisconsin: 'Wisconsin',
            Wyoming: 'Wyoming',
        },

    },
    button: { nextStep: 'Tarkaanfii Itti Aanu', submit: 'Galchuu' },
    error: {
        required: 'jiraachuu qaba',
        email: 'Imeelii ta\'uu qaba',
        phone: 'Lakkoofsa bilbilaa sirrii hin taane',
        date: 'Guyyaa sirrii hin taane',
        driverAge: { dateOfBirth: 'Umuriin isaa waggaa 16 hanga 100 gidduutti ta’uu qaba' },
        name: 'Maqaa seera qabeessa ta\'uu qaba',
        streetAddress: 'Teessoo daandii seera qabeessa ta’uu qaba',
        city: 'Magaalaa sirrii ta\'uu qabdi',
        zipCode: 'Ziip koodii sirrii ta\'uu qaba',
        driverLicense: 'Hayyama konkolaachisummaa seera qabeessa ta’uu qaba',
        atLeast1: 'Yoo xiqqaate tokko filadhu',
        zipCodeMatchState: 'ziip code haala waliin walsimuu qaba',
        equalTo: {    emailСonfirm: 'Your email address does not match the entry above. Please try again.',},
    },
    other: {
        step: 'Tarkaanfii',
        oneMoreStep: 'Tarkaanfii tokko dabalataan',
        toAccessTheBenefits: 'faayidaa argachuuf',
        DUIsHereForYou:
            'DU siif as jira! Mirga hojjettoota appii falmachuuf nu waliin ta\'aa! \nHojjettoota geejjibaa hanga ammaatti faayidaan akka hin jirre hubadhaa.',
        byCompletingTheInformation:
        'Bằng cách hoàn thành thông tin ở trên và nhấp vào “Gửi”, bạn xác nhận rằng bạn đã đọc, hiểu và đồng ý bị ràng buộc bởi Điều khoản sử dụng của chúng tôi. Ngoài ra, bạn đồng ý rằng chúng tôi có thể sử dụng và tiết lộ thông tin của bạn như được quy định trong Chính sách quyền riêng tư của chúng tôi',
        goodJob: 'Làm tốt lắm!',
        submitAndGetBenefits: 'Gửi biểu mẫu để có được quyền truy cập vào các lợi ích!',
        congratulations: 'Chúc mừng',
        weWillBeInTouchSoon: 'Chúng tôi sẽ liên lạc sớm!',
        successThanks: 'NU WALIIN WAJJIN TA\'UU KEESSATTI GALATOOMAA',
        successMesage: 'Mee email baga nagaan dhuftan argachuuf inbox keessan ilaalaa',
        useBenefits: 'Sử dụng lợi ích của bạn',
        contactUs: 'Liên hệ chúng tôi',
    },
}
