import { useTranslation } from 'react-i18next'
import { Button, FooterItem } from 'src/components'
import {Email, Letter} from 'src/components/Illustrations/Illustrations'
import cx from 'classnames'
import { useField, useFormikContext } from 'formik'

import styles from './success-block.module.scss'
import { getUrlParamsValues } from 'src/helpers'
import { useEffect } from 'react'
import { typeOfDeliveryApp } from 'src/App'
import { useState } from 'react'
import { REDIRECT_DELIVERY_WEB } from 'src/config'

const SuccessBlock = () => {
  const { t } = useTranslation()
  const { contracts } = getUrlParamsValues()
  const { values }: any = useFormikContext()
  const [isDeliveryApp, setIsDeliveryApp] = useState<boolean>(false)

  const typeApp = typeOfDeliveryApp(values?.carCompanies)

  useEffect(() => {
    if (typeApp === 'Delivery') {
      setIsDeliveryApp(true)
    }
  }, [values])

  const handleContactUs = () => {
    window.location.href = 'https://www.driversunionwa.org/'
    return
  }

  const handleOtherButton = () => {
    window.location.href = 'https://www.driversunionwa.org/benefits'
    return
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>

          <Email className={styles.containerImg} />

        <div className={cx(styles.basicItalicBoldHuge, styles.marginTen)}>
          {t('other.successThanks')}
        </div>
        <div className={cx(styles.marginDouble, styles.basicComment)}>
          {t('other.successMesage')}
        </div>
        <div className={styles.containerButtons}>
        {/*  <Button onClick={handleOtherButton}>
            {isDeliveryApp ? 'Delivery Workers' : t('other.useBenefits')}
          </Button>
          <Button onClick={handleContactUs}>{t('other.contactUs')}</Button>*/}
        </div>
      </div>
    </div>
  )
}

export default SuccessBlock
