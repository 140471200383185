import { useState, useEffect } from 'react'
import { useFormik, FormikProvider } from 'formik'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import ReactGA from 'react-ga4'
import * as yup from 'yup'

import { appLocalStorage } from 'src/libs'

import { BasicTemplate } from 'src/templates'

import { FormField } from 'src/components'

import { useSendFormData } from 'src/hooks'

import { getDependingCheck } from 'src/helpers'

import {
  CONST_VALUES,
  EXCEPTIONS_FOR_INITIAL_LOCAL_STORAGE,
} from 'src/config'

import formData from 'src/formData.json'
import packageJson from 'src/../package.json'

import { IField, ILeadMutationData, LeadMutationDataKey } from 'src/interfaces'
import {isValid} from "all-iso-language-codes";
import mixpanel from "mixpanel-browser";

const { version: appVersion } = packageJson

interface IFormData {
  steps: {
    fields: IField[]
  }[]
}

const getInitialValues = (data: IFormData) => {
  return data.steps.reduce(
    (a, c) => {
      c.fields.forEach((f) => {
        if (!f.disabled) {
          const dependingCheck = getDependingCheck(a, f.dependsOn)
          if (dependingCheck) {
            a[f.name] =
              f.defaultValue || (f.input === 'checks' ? ([] as string[]) : '')
          }
        }
      })

      return a
    },
    { ...CONST_VALUES } as any,
  ) //TODO better
}

const getSubmittingValues = (
  stepIndex: number,
  values: any,
  data: IFormData,
) => {
  let resultObj = {
    id: values.id,
    "templateId": "5f9fe765-c4b2-4860-b5bb-e5a79da7e15a",
    "email": values['email'],
    "isCompleted":  stepIndex === 0 ? false : true,
    "isReviewed": false,
    "version": "1",
    "payload": {
      "steps": [
        {
          "fields": [
            {
              "name": "browserDevice",
              "input": "basic",
              "label": "browserDevice",
              "value": values['browserDevice'],
            },
            {
              "name": "browserLanguage",
              "input": "basic",
              "label": "browserLanguage",
              "value":  values['browserLanguage'],
            },
            {
              "name": "source",
              "input": "basic",
              "label": "source",
              "value":  "DU", ///
            },
            {
              "name": "email",
              "input": "basic",
              "label": "email",
              "value":  values['email'],
            },
            {
              "name": "preferredLanguage",
              "input": "basic",
              "label": "preferredLanguage",
              "value":  values['preferredLanguage'],
            },
            {
              "name": "firstName",
              "input": "basic",
              "label": "firstName",
              "value":  values['firstName'],
            },
            {
              "name": "lastName",
              "input": "basic",
              "label": "lastName",
              "value":  values['lastName'],
            },
            {
              "name": "phoneNumber",
              "input": "basic",
              "label": "phoneNumber",
              "value":  values['phoneNumber'],
            },
            {
              "name": "carCompanies",
              "input": "basic",
              "label": "carCompanies",
              "value": JSON.stringify(values['carCompanies']) ,
            }
          ]
        },
        {
          "fields": [
            {
              "name": "gender",
              "input": "basic",
              "label": "gender",
              "value":  values['gender'],
            },
            {
              "name": "birthDate",
              "input": "basic",
              "label": "birthDate",
              "value":  values['dateOfBirth'],
            },
            {
              "name": "street",
              "input": "basic",
              "label": "street",
              "value":  values['streetAddress'],
            },
            {
              "name": "city",
              "input": "basic",
              "label": "city",
              "value":  values['city'],
            },
            {
              "name": "state",
              "input": "basic",
              "label": "state",
              "value":  values['stateSelectHome'],
            },
            {
              "name": "postalCode",
              "input": "basic",
              "label": "postalCode",
              "value":  values['zipCode'],
            },
            {
              "name": "driversLicenseNumber",
              "input": "basic",
              "label": "driversLicenseNumber",
              "value":  values['driversLicenseNumber'],
            }
          ]
        }
      ]
    },
    // ...CONST_VALUES,
    // id: values.id,
    // nationBuilderId: !!values.nationBuilderId
    //   ? values.nationBuilderId
    //   : undefined,
    // formDropoff: stepIndex === 0 ? true : false,
    // status: stepIndex === 0 ? 'Partial' : 'Signed Up',
  } as any //TODO better



 /* data.steps.reduce((a, c, i) => {
    if (i <= stepIndex) {
      c.fields.forEach((f) => {
        if (
          !f.disabled &&
          !f.notForApi &&
          typeof values[f.name] !== 'undefined'
        ) {
          resultObj[f.name] = values[f.name]
        }
      })
    }
    return a
  }, {} as any) //TODO better*/

  return resultObj
}

const getValidationSchema = (
  values: ILeadMutationData,
  stepIndex: number,
  data: IFormData,
) => {
  const validationObj = data.steps.reduce((a, c, i) => {
    if (i <= stepIndex) {
      c.fields.forEach((f) => {
        if (f.validation && !f.disabled) {


          if (f.dependsOn) {
            const dependingCheck = getDependingCheck(values, f.dependsOn)

            if (!dependingCheck) {
              return a
            }
          }

          if (f.input === 'checks') {
            //TODO make it normal
            a[f.name] = yup.array().of(yup.string()).min(1, 'atLeast1')
          } else {
            let currentRules = yup.string()

            Object.entries(f.validation).forEach(([ruleName, ruleValue]) => {
              currentRules = (() => {
                if (!ruleValue) {
                  return currentRules
                }

                switch (ruleName) {
                  case 'required':
                  case 'email':
                  case 'phone':
                  case 'date':
                  case 'driverAge':
                  case 'name':
                  case 'streetAddress':
                  case 'city':
                  case 'zipCode':
                  case 'driverLicense':
                    return currentRules[ruleName](ruleName)
                  case 'equalTo':
                    return currentRules.oneOf(
                        [yup.ref(ruleValue as string)],
                        ruleName,
                    )
                  case 'zipCodeMatchState':
                    return currentRules[ruleName](ruleName,values.stateSelectHome)

                }

                return currentRules
              })()
            })

            a[f.name] = currentRules
          }
        }
      })
    }

    return a
  }, {} as any) //TODO better

  return yup.object().shape(validationObj)
}

export const typeOfDeliveryApp = (carCompanies: string[] | undefined) => {
  const deliveryApps = [
    'uberEats',
    'doordash',
    'grubhub',
    'postmates',
    'seamless',
  ]
  const riderApps = ['uber', 'lyft']
  const includesDeliveryApp = deliveryApps.some((app) =>
    carCompanies?.includes(app),
  )
  const includesRideryApp = riderApps.some((app) => carCompanies?.includes(app))
  if (includesDeliveryApp && includesRideryApp) {
    return 'Rider&Delivery'
  }
  if (includesDeliveryApp && !includesRideryApp) {
    return 'Delivery'
  }
  if (!includesDeliveryApp && includesRideryApp) {
    return 'Rider'
  }
  return null
}

const App = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0) //TODO mb create context ?
  const [formSuccess, setFormSuccess] = useState<boolean>(false)

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `/signup/?page=step${currentStepIndex + 1}`,
    })
  }, [currentStepIndex])

  useEffect(() => {
    if (formSuccess) {
      ReactGA.send({ hitType: 'pageview', page: `/signup/?page=success` })
    }
  }, [formSuccess])

  const { t, i18n } = useTranslation()

  const formik = useFormik<ILeadMutationData>({
    initialValues: {
      ...getInitialValues(formData),
    },
    validationSchema: () =>
      yup.lazy((values) =>
        getValidationSchema(values, currentStepIndex, formData),
      ),
    onSubmit: async (values, { setFieldValue }) => {
      ReactGA.event({
        category: currentStepIndex === 0 ? 'Next Button' : 'Submit Button',
        action:
          currentStepIndex === 0 ? 'Next Button Clicked' : 'Form Submitted',
      })

      const newValues = {
        ...values,
        carCompanies: values.carCompanies?.map((c) =>
          c.replace('__other__', ''),
        ),
      }

      await formSendDataMutation
        .mutateAsync(getSubmittingValues(currentStepIndex, newValues, formData))
        .then((result) => {
          if (currentStepIndex === 0) {
            setFieldValue('id', result.data?.id)
            setFieldValue('nationBuilderId', result.data?.nationBuilderId)
          } else {
            setFormSuccess(true)
          }
        })
    },
  })

  const formSendDataMutation = useSendFormData(
    typeof formik?.values?.id === 'undefined' ? 'create' : 'update',
  )

  useEffect(() => {
    appLocalStorage.session = {
      appVersion,
      timestamp: dayjs().unix(),
      formState: Object.entries(formik.values).reduce((a, [key, value]) => {
        if (!EXCEPTIONS_FOR_INITIAL_LOCAL_STORAGE.includes(key)) {
          a[key] = value
        }
        return a
      }, {} as { [key: string]: any }),
    }
  }, [formik.values])

  useEffect(() => {
    // console.log('~~~~~~~~~~~~~~~')
    // console.log('CURRENT VALUES:')
    // console.log(formik.values)
    // console.log('CURRENT ERRORS:')
    // console.log(formik.errors)
    // console.log('~~~~~~~~~~~~~~~')
  }, [formik.values, formik.errors])

  function detectSubDomainLanguage() {
    const regexp = /\/\/(.*)\..*\..*/
    let res = document.location.href.match(regexp)
    return !!res && isValid(res[1])
      ? res[1]
      : null
  }

  useEffect(() => {
    // Detect language from subdomain
    const subDomainLanguage = detectSubDomainLanguage()
    if (subDomainLanguage) {
      i18n.changeLanguage(subDomainLanguage).then()
      if (isValid(subDomainLanguage) && subDomainLanguage!=='www'){
        formik.values.preferredLanguage = subDomainLanguage
      }

      mixpanel.track('Change Language', {subDomainLanguage});
    }
  }, [])

  useEffect(() => {
   if (currentStepIndex==1){
     window.scrollTo(0, 0)
   }
  }, [currentStepIndex])


  const currentStep = formData.steps?.[currentStepIndex]

  const handleNextStep = async () => {
    if (currentStep) {
      const currentStepFields = (currentStep.fields as IField[]).reduce(
        (a: { [key: string]: boolean }, c) => {
          a[c.name as LeadMutationDataKey] = true
          return a
        },
        {} as { [key: string]: boolean },
      )

      const errors = await formik.setTouched(currentStepFields, true)

      const isNotValid = Boolean(
        Object.keys(currentStepFields).find(
          (key) => errors?.[key as LeadMutationDataKey],
        ),
      )

      if (!isNotValid) {
        await formik.submitForm()

        if (currentStepIndex + 1 < formData.steps.length) {
          setCurrentStepIndex(currentStepIndex + 1)
        }

        formik.setTouched({}, false)
      } else {
        mixpanel.track('Invalid Form ',{currentStepIndex});
        mixpanel.track('Form Errors', {errors:formik?.errors});
      }
    }
  }

  const handleGooglePlace = (result: google.maps.places.PlaceResult | null) => {
    if (result) {
      const newValues = {
        streetAddress: '',
      } as {
        city?: string
        stateSelectHome?: string
        streetAddress?: string
        zipCode?: string
      }

      result.address_components?.reverse().forEach((a) => {
        if (a.types.includes('street_number')) {
          newValues.streetAddress = `${a.long_name} ${newValues.streetAddress}`
        }

        if (a.types.includes('route')) {
          newValues.streetAddress = a.long_name
        }

        if (a.types.includes('sublocality')) {
          if (!newValues.city) {
            newValues.city = a.long_name
          }
        }

        if (a.types.includes('locality')) {
          newValues.city = a.long_name
        }

        if (a.types.includes('administrative_area_level_1')) {
          newValues.stateSelectHome = a.short_name.toUpperCase()
        }

        if (a.types.includes('postal_code')) {
          newValues.zipCode = a.long_name
        }
      })

      formik.setValues({
        ...formik.values,
        ...newValues,
      })
    } else {
      formik.setValues({
        ...formik.values,
        streetAddress: '',
        city: '',
        stateSelectHome: '',
        zipCode: '',
      })
    }
  }

  const handleStrictChange = (name: string, value: any) => {
    //TODO better
    switch (name) {
      case 'streetAddress':
        handleGooglePlace(value)
        break
    }
  }

  if (!currentStep) {
    return null
  }

  return (
    <FormikProvider value={formik}>
      <BasicTemplate
        loading={formSendDataMutation.isLoading}
        stepsCount={formData.steps.length}
        currentStepIndex={currentStepIndex}
        isSuccess={formSuccess}
        onNext={handleNextStep}
      >
        <>
          {(currentStep.fields as IField[])
            .filter((f: IField) => !f.disabled)
            .map((f: IField) => (
              <FormField
                key={f.name}
                {...f}
                onChange={(value) => handleStrictChange(f.name, value)}
              />
            ))}
        </>
      </BasicTemplate>
    </FormikProvider>
  )
}

export default App

