export default {
  label: {
    preferredLanguage: 'اختر اللغة',
    email: 'البريد الإلكتروني',
    emailСonfirm: 'أعد إدخال بريدك الإلكتروني',
    firstName: 'الاسم الاول',
    lastName: 'الكنية',
    carCompanies: 'ما هي التطبيقات التي تعمل بها؟',
    gender: 'جنس',
    phoneNumber: 'رقم الهاتف',
    dateOfBirth: 'تاريخ الولادة',
    streetAddress: 'عنوان الشارع',
    apartment: 'شقة ، وحدة ، بدلة ، أو طابق #',
    city: 'مدينة',
    stateSelectHome: 'حالة*',
    zipCode: 'الرمز البريدي',
    driversLicenseNumber: 'رخصة السائق #',
    driversLicenseNumberСonfirm: 'إعادة إدخال رخصة القيادة #',
    driversId: 'ID',
    driversIdСonfirm: 'إعادة إدخال المعرف #',
    tlcNumber: 'TLC',
    tlcNumberСonfirm: 'أعد إدخال TLC #',
    howYouDeliver: 'كيف تقدم',
    whatStateDoYouDriveIn: 'ما الولاية التي تقود فيها',
    "Enter gender":"أدخل الجنس",
    "Enter Language":"أدخل اللغة"
  },
  placeholder: {
    email: 'أدخل بريدك الإلكتروني',
    emailСonfirm: 'أعد إدخال بريدك الإلكتروني',
    firstName: 'أدخل أسمك',
    lastName: 'أدخل اسمك الأخير',
    gender: 'حدد نوع الجنس',
    phoneNumber: '000-000-0000',
    streetAddress: 'أدخل عنوان الشارع الخاص بك',
    apartment: 'يدخل #',
    city: 'أدخل المدينة',
    stateSelectHome: 'اختر ولايه',
    zipCode: 'أدخل رمزك البريدي',
    driversLicenseNumber: 'أدخل رخصة القيادة الخاصة بك',
    driversLicenseNumberСonfirm: 'إعادة إدخال رخصة القيادة #',
    driversId: 'أدخل المعرف',
    driversIdСonfirm: 'إعادة إدخال المعرف #',
    tlcNumber: 'أدخل رقم TLC الخاص بك',
    tlcNumberСonfirm: 'أعد إدخال TLC #',
    other: 'آخر',
  "Enter Language":"أدخل اللغة",
    "Enter gender":"أدخل الجنس",
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
      other: 'Other',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
      taxi: 'Taxi',
      'flat-rate': 'Flat-Rate',
    },
    gender: {
      Male: 'ذكر',
      Female: 'أنثى',
      Other: 'آخر',  other: 'آخر',
      'Prefer not to say': 'افضل عدم القول',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: {
      car: 'جمل',
      bike: 'دراجة هوائية',
      onFoot: 'سيرا على الاقدام',
    },
  },
  button: { nextStep: 'الخطوة التالية', submit: 'يُقدِّم' },
  error: {
    required: 'يجب أن يكون حاضر',
    email: 'يجب أن يكون البريد الإلكتروني',
    phone: 'رقم الهاتف غير صحيح',
    date: 'تاريخ غير صالح',
    driverAge: { dateOfBirth: 'يجب أن يكون عمرك بين 16 و 100 عام' },
    name: 'يجب أن يكون اسمًا صالحًا',
    streetAddress: 'يجب أن يكون عنوان شارع صالحًا',
    city: 'يجب أن تكون مدينة صالحة',
    zipCode: 'يجب أن يكون رمزًا بريديًا صالحًا',
    driverLicense: 'يجب أن تكون رخصة قيادة صالحة',
    atLeast1: 'حدد خيارًا واحدًا على الأقل',
    zipCodeMatchState: 'يجب أن يتطابق الرمز البريدي مع الولاية',
    equalTo: {
      emailСonfirm: 'عنوان بريدك الإلكتروني لا يتطابق مع الإدخال أعلاه. حاول مرة اخرى.',
      driversLicenseNumberСonfirm: 'يجب أن تتطابق رخصة القيادة',
      driversIdСonfirm: 'يجب أن يتطابق المعرف',
      tlcNumberСonfirm: 'يجب أن تتطابق TLC',
    },
  },
  other: {
    step: 'خطوة',
    oneMoreStep: 'خطوة أخرى أيضا',
    toAccessTheBenefits: 'للوصول إلى الفوائد',
    DUIsHereForYou:
      'DU هنا من أجلك! انضم إلينا للقتال من أجل حقوق عامل التطبيق!\nلاحظ أنه لا توجد فوائد لعمال التوصيل حتى الآن.',
    byCompletingTheInformation:
      'من خلال استكمال المعلومات الواردة أعلاه والنقر فوق "إرسال" ، فإنك تؤكد أنك قد قرأت وفهمت ووافقت على <termsOfUseLink> شروط الاستخدام </termsOfUseLink>. بالإضافة إلى ذلك ، لقد وافقت على أنه يجوز لنا استخدام معلوماتك والكشف عنها على النحو المنصوص عليه في <privacyPolicyLink> سياسة الخصوصية </ privacyPolicyLink>',
    goodJob: 'شروط الخدمة.',
    submitAndGetBenefits: 'سياسة الخصوصية.',
    congratulations: 'تهانينا',
    weWillBeInTouchSoon: 'سنكون على اتصال قريبا!',
    successThanks: 'شكرا لانضمامك إلينا!',
    successMesage: 'يرجى التحقق من صندوق الوارد الخاص بك للحصول على بريد إلكتروني ترحيبي!',
    useBenefits: 'استخدم الفوائد الخاصة بك',
    contactUs: 'اتصل بنا',
  },
}
