export default {
    label: {
        preferredLanguage: 'Chọn ngôn ngữ',
        email: 'e-mail',
        emailСonfirm: 'Nhập lại email của bạn',
        firstName: 'Tên đầu tiên',
        lastName: 'Tên cuối cùng',
        carCompanies: 'Bạn làm việc trên (những) ứng dụng nào?',
        gender: 'Giới',
        phoneNumber: 'Số điện thoại',
        dateOfBirth: 'Ngày sinh',
        streetAddress: 'Địa chỉ đường phố',
        apartment: 'Căn hộ, đơn vị, bộ đồ, hoặc tầng #',
        city: 'Thành phố',
        stateSelectHome: 'tiểu bang',
        zipCode: 'Mã Bưu Chính',
        driversLicenseNumber: "Bằng lái xe #",
        whatStateDoYouDriveIn: 'Bạn lái xe ở bang nào',
        haveHealthInsurance: 'Bạn hiện có bảo hiểm y tế không?',
        "Enter Language": "Nhập ngôn ngữ",
        "Enter gender": "Nhập giới tính"
    },
    placeholder: {
        email: 'Nhập email của bạn',
        emailСonfirm: 'Nhập lại email của bạn',
        firstName: 'Nhập tên của bạn',
        lastName: 'Nhập họ của bạn',
        gender: 'Chọn giới tính',
        phoneNumber: '000-000-0000',
        streetAddress: 'Nhập địa chỉ đường phố của bạn',
        apartment: 'Đi vào #',
        city: 'nhập thành phố',
        stateSelectHome: 'Chọn tiểu bang',
        zipCode: 'Nhập mã zip của bạn',
        driversLicenseNumber: "Nhập giấy phép lái xe của bạn",
        driversId: 'Nhập ID của bạn',
        other: 'Khác',
        "Enter gender": "Nhập giới tính",
        "Enter Language": "Nhập ngôn ngữ"
    },
    options: {
        preferredLanguage: {
            en: 'English',
            es: 'Español',
            chinese: '中国人',
            Fre: 'Français',
            ru: 'Русский',
            pa: 'ਪੰਜਾਬੀ',
            Hindi: 'हिन्दी',
            bn: 'বাংলা',
            ur: 'اردو',
            Arabic: 'عربي',
            am: 'Amharic',
            so: 'Somali',
            vi: 'Tiếng Việt',
            om: 'Oromo',

            Other: 'Other',
        },
        carCompanies: {
            uber: 'Uber',
            uberEats: 'Uber Eats',
            lyft: 'Lyft',
            doordash: 'Doordash',
            grubhub: 'Grubhub',
            postmates: 'Postmates',
            instacart: 'Instacart',
            seamless: 'Seamless',
            taxi: 'Taxi',
            'flat-rate': 'Flat-Rate',
        },
        gender: {
            Male: 'Nam giới',
            Female: 'Nữ giới',
            Other: 'Khác', other: 'Khác',
            'Prefer not to say': 'thà không nói',
        },
        state: {
            'New York': 'New York',
            'New Jersey': 'New Jersey',
            Connecticut: 'Connecticut',
            Pennsylvania: 'Pennsylvania',
            Alabama: 'Alabama',
            Alaska: 'Alaska',
            Arizona: 'Arizona',
            Arkansas: 'Arkansas',
            California: 'California',
            Colorado: 'Colorado',
            Delaware: 'Delaware',
            'District Of Columbia': 'District Of Columbia',
            Florida: 'Florida',
            Georgia: 'Georgia',
            Hawaii: 'Hawaii',
            Idaho: 'Idaho',
            Illinois: 'Illinois',
            Indiana: 'Indiana',
            Iowa: 'Iowa',
            Kansas: 'Kansas',
            Kentucky: 'Kentucky',
            Louisiana: 'Louisiana',
            Maine: 'Maine',
            Maryland: 'Maryland',
            Massachusetts: 'Massachusetts',
            Michigan: 'Michigan',
            Minnesota: 'Minnesota',
            Mississippi: 'Mississippi',
            Missouri: 'Missouri',
            Montana: 'Montana',
            Nebraska: 'Nebraska',
            Nevada: 'Nevada',
            'New Hampshire': 'New Hampshire',
            'New Mexico': 'New Mexico',
            'North Carolina': 'North Carolina',
            'North Dakota': 'North Dakota',
            Ohio: 'Ohio',
            Oklahoma: 'Oklahoma',
            Oregon: 'Oregon',
            'Rhode Island': 'Rhode Island',
            'South Carolina': 'South Carolina',
            'South Dakota': 'South Dakota',
            Tennessee: 'Tennessee',
            Texas: 'Texas',
            Utah: 'Utah',
            Vermont: 'Vermont',
            Virginia: 'Virginia',
            Washington: 'Washington',
            'West Virginia': 'West Virginia',
            Wisconsin: 'Wisconsin',
            Wyoming: 'Wyoming',
        },

    },
    button: {nextStep: 'Bước tiếp theo', submit: 'Nộp'},
    error: {
        required: 'phải có mặt',
        email: 'Phải là email',
        phone: 'số điện thoại không hợp lệ',
        date: 'Ngày không hợp lệ',
        driverAge: {dateOfBirth: 'Phải từ 16 đến 100 tuổi'},
        name: 'Phải là một tên hợp lệ',
        streetAddress: 'Phải là một địa chỉ đường phố hợp lệ',
        city: 'Phải là một thành phố hợp lệ',
        zipCode: 'Phải là một mã zip hợp lệ',
        driverLicense: 'Phải là giấy phép lái xe hợp lệ',
        atLeast1: 'Chọn ít nhất một',
        zipCodeMatchState: 'mã zip phải khớp với tiểu bang',
        equalTo: {emailСonfirm: 'Your email address does not match the entry above. Please try again.',},
    },
    other: {
        step: 'Bước chân',
        oneMoreStep: 'thêm một bước',
        toAccessTheBenefits: 'để truy cập các lợi ích',
        DUIsHereForYou:
            'DU ở đây dành cho bạn! Hãy tham gia cùng chúng tôi để đấu tranh cho quyền của nhân viên ứng dụng! \nLưu ý rằng chưa có lợi ích nào cho nhân viên giao hàng.',
        byCompletingTheInformation:
            'Bằng cách hoàn thành thông tin ở trên và nhấp vào “Gửi”, bạn xác nhận rằng bạn đã đọc, hiểu và đồng ý bị ràng buộc bởi Điều khoản sử dụng của chúng tôi. Ngoài ra, bạn đồng ý rằng chúng tôi có thể sử dụng và tiết lộ thông tin của bạn như được quy định trong Chính sách quyền riêng tư của chúng tôi',
        goodJob: 'Làm tốt lắm!',
        submitAndGetBenefits: 'Gửi biểu mẫu để có được quyền truy cập vào các lợi ích!',
        congratulations: 'Chúc mừng',
        weWillBeInTouchSoon: 'Chúng tôi sẽ liên lạc sớm!',
        successThanks: 'Cảm ơn bạn đã tham gia',
        successMesage: 'Vui lòng kiểm tra hộp thư đến của bạn để nhận email chào mừng',
        useBenefits: 'Sử dụng lợi ích của bạn',
        contactUs: 'Liên hệ chúng tôi',
    },
}
