import   moment from 'moment'
import * as yup from 'yup'
import zipState from "zip-state";

declare module 'yup' {
  interface StringSchema {
    driverAge(msg?: string): this
    date(msg?: string): this
    phone(msg?: string): this
    name(msg?: string): this
    streetAddress(msg?: string): this
    city(msg?: string): this
    zipCode(msg?: string): this
    driverLicense(msg?: string): this
    zipCodeMatchState(msg?: string,val?: string): this
  }
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  'driverAge',
  function (message = 'must be between 16 and 100') {
    return this.test('valid-driverAge', message, function (value = '') {
      return  true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'date',
  function (message = 'must be a valid date') {
    return this.test('valid-date', message, function (value = '') {
      return value
        ? (moment(value, 'YYYY-MM-DD').isValid() && value.length>9)
        : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'phone',
  function (message = 'must be a valid phone number') {
    return this.test('valid-phone', message, function (value = '') {
      return value
        ? /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
            value,
          ) && value.length>11
        : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'name',
  function (message = 'must be a valid name') {
    return this.test('valid-name', message, function (value = '') {
      return value ? /^[a-zA-Z\s\-]*$/.test(value) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'streetAddress',
  function (message = 'must be a valid street address') {
    return this.test('valid-streetAddress', message, function (value = '') {
      return value ? /^[a-zA-Z0-9\s\-\&\.]*$/.test(value) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'city',
  function (message = 'must be a valid city') {
    return this.test('valid-city', message, function (value = '') {
      return value ? /^[a-zA-Z\s\-]*$/.test(value) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'zipCode',
  function (message = 'must be a valid zip code') {
    return this.test('valid-zipCode', message, function (value = '') {
        console.log(zipState(value) , !!zipState(value) && (value.length==5 || value.length==5) )
      return value ? !!zipState(value) && (value.length==5 || value.length==5) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'driverLicense',
  function (message = 'must be a valid driver license') {
    return this.test('valid-driverLicense', message, function (value = '') {
      return value ? /^[a-zA-Z0-9]*$/.test(value) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
    yup.string,
    'zipCodeMatchState',
    function (message = 'zip code must match the home state',val='') {
        return this.test('zip-code-match-state', message, function (value = '', context) {
            return val.toUpperCase()===zipState(value)?.toUpperCase()
        })
    },
)

export default yup
