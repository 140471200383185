import { useFormikContext } from 'formik'

import {
  StateSelect,
  Select,
  Checks,
  PhoneInput,
  DateInput,
  GooglePlacesInput,
  Input,
} from 'src/components'

import { getDependingCheck } from 'src/helpers'

import { IField, ILeadMutationData } from 'src/interfaces'

const FormField = (props: IField) => {
  const { values } = useFormikContext()

  if (props.dependsOn) {
    const dependingCheck = getDependingCheck(
      values as ILeadMutationData,
      props.dependsOn,
    )

    if (!dependingCheck) {
      return null
    }
  }

  let Component = null

  switch (props.input) {
    case 'select':
      Component = Select
      break
    case 'stateSelect':
      Component = StateSelect
      break
    case 'checks':
      Component = Checks
      break
    case 'phone':
      Component = PhoneInput
      break
    case 'date':
      Component = DateInput
      break
    case 'googlePlaces':
      Component = GooglePlacesInput
      break
    default:
      Component = Input
  }

  if (!Component) {
    return null
  }

  return <Component {...props} />
}

export default FormField
