import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import ReactGA from 'react-ga4'
import mixpanel from 'mixpanel-browser';

import 'src/i18n'
import 'src/libs/yup'

import { apiClient } from './api'

import App from './App'

import { GooglePlacesApiProvider } from 'src/context'

import { GA_TRACKING_CODE } from 'src/config'
import * as Sentry from "@sentry/react";

import './styles/globals.scss'
import {BrowserTracing} from "@sentry/tracing";

ReactGA.initialize(GA_TRACKING_CODE, {
  gtagOptions: {
    send_page_view: false,
  },
})

Sentry.init({
  dsn: "https://d83be3bfa41c40b09cdc76ed1230917a@o1204727.ingest.sentry.io/4504832676462592",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

mixpanel.init('254dd715e5d6cae6c552a8ba2d7d574b', {debug: true});
mixpanel.track('Sign up page init');

const rootNode = document.getElementById('root')

if (rootNode) {
  const root = ReactDOM.createRoot(rootNode)

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={apiClient}>
        <GooglePlacesApiProvider>
          <App />
        </GooglePlacesApiProvider>
      </QueryClientProvider>
    </React.StrictMode>,
  )
}
