import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Label } from 'src/components'

import styles from './field-wrapper.module.scss'

import { IField } from 'src/interfaces'

const FieldWrapper = (props: React.PropsWithChildren<IField>) => {
  const {
    name,
    label,
    placeholder,
    width,
    validation,
    strictError,
    align,
    children
  } = props

  const { t } = useTranslation()

  const [field, meta] = useField({ name })

  const isRequired = validation?.required === true

  const errorStr = (() => {
    if (strictError) {
      return t(`error.${strictError}`)
    }

    if (meta.error && meta.touched) {
      return (
        t(`error.${meta.error}.${name}`, { defaultValue: null }) ||
        t(`error.${meta.error}`)
      )
    }

    return null
  })()

  return (
    <div
      className={cx(
        styles.wrapper,
        width && styles[`w${width}`],
        align === 'center' && styles.center,
      )}
    >
      <div className={styles.top}>
        <div className={cx(styles.labelWrapper)}>
          {label ? (
            <Label>{`${t('label.' + label)}${isRequired ? '*' : ''}`}</Label>
          ) : null}
        </div>
        <div className={styles.error}>{errorStr}</div>
      </div>
      {children}
    </div>
  )
}

export default FieldWrapper
