export default {
  label: {
    preferredLanguage: 'Selecciona el idioma hablado en casa',
    email: 'Ingrese su Correo Electrónico',
    emailСonfirm: 'Vuelva a ingresar su Correo Electrónico',
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    carCompanies: '¿Para qué aplicación(es) trabajas?',
    gender: 'Género',
    phoneNumber: 'Número de teléfono',
    dateOfBirth: 'Fecha de nacimiento',
    streetAddress: 'Dirección',
    apartment: 'Apt., o # de piso',
    city: 'Ciudad',
    stateSelectHome: 'Estado',
    zipCode: 'Código postal',
    driversLicenseNumber: 'Número de su Licencia de Conducir',
    driversLicenseNumberСonfirm:
      'Vuelva a ingresar el número de su Licencia de Conducir',
    driversId: 'ID',
    driversIdСonfirm: 'Vuelva a ingresar el ID',
    tlcNumber: 'Número de TLC',
    tlcNumberСonfirm: 'Vuelva a ingresar el número de TLC',
    howYouDeliver: '¿Cómo entregas?',
    whatStateDoYouDriveIn: '¿En qué estado conduces?',
    "Enter Language":"Introducir idioma",
    "Enter gender":"Ingrese el género"
  },
  placeholder: {
    email: 'Ingrese su Correo Electrónico',
    emailСonfirm: 'Vuelva a ingresar su Correo Electrónico',
    firstName: 'Ingrese su Nombre',
    lastName: 'Ingrese su Apellido',
    gender: 'Seleccione su Género',
    phoneNumber: '000-000-0000',
    streetAddress: 'Ingrese su Dirección de Domicilio',
    apartment: 'Ingrese el número de su apartamento',
    city: 'Ingrese su Ciudad',
    stateSelectHome: 'Seleccione su Estado',
    zipCode: 'Ingrese su Código Postal',
    driversLicenseNumber: 'Ingrese el número de su Licencia de Conducir',
    driversLicenseNumberСonfirm:
      'Vuelva a ingresar el número de su Licencia de Conducir',
    driversId: 'Ingrese su ID',
    driversIdСonfirm: 'Vuelva a ingresar el ID',
    tlcNumber: 'Ingrese su número de TLC',
    tlcNumberСonfirm: 'Vuelva a ingresar el número de TLC',
    other: 'Otro',
  "Enter Language":"Introducir idioma",
    "Enter gender":"Ingrese el género"
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
      other: 'Other',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
      taxi: 'Taxi',
      'flat-rate': 'Flat-Rate',
    },
    gender: {
      Male: 'Masculino',
      Female: 'Femenino',
      Other: 'Otro', other: 'Otro',
      'Prefer not to say': 'Prefiero no decirlo',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'Coche', bike: 'Bicicleta', onFoot: 'A pie' },
  },
  button: { nextStep: 'Próximo paso', submit: 'Enviar' },
  error: {
    required: 'Debe estar presente',
    email: 'Debe ser un correo electrónico',
    phone: 'Número de teléfono inválido',
    date: 'Fecha inválida',
    driverAge: { dateOfBirth: 'Debes tener entre 16 y 100 años' },
    name: 'Debe ser un nombre válido',
    streetAddress: 'Debe ser una dirección válida',
    city: 'Debe ser una ciudad válida',
    zipCode: 'Debe ser un código postal válido',
    driverLicense: 'Debe ser una licencia de conducir válida',
    zipCodeMatchState: 'el código postal debe coincidir con el estado',
    atLeast1: 'Seleccione al menos una opción',
    equalTo: {
      driversLicenseNumberСonfirm:
        'El número de la licencia de conducir debe coincidir',
      driversIdСonfirm: 'El ID debe coincidir',
      tlcNumberСonfirm: 'El número de TLC debe coincidir',
      emailСonfirm: 'Your email address does not match the entry above. Please try again.',
    },
  },
  other: {
    step: 'Paso',
    oneMoreStep: 'Un paso más',
    toAccessTheBenefits: 'para acceder a los beneficios',
    DUIsHereForYou:
      '¡DU está aquí para usted! ¡Únase a nosotros para luchar por los derechos de los trabajadores de aplicaciones!\nTenga en cuenta que todavía no hay beneficios para los trabajadores de entrega.',
    byCompletingTheInformation:
      'Al completar la información anterior y hacer click en "Enviar", usted confirma que ha leído, entendido y aceptado nuestros <termsOfUseLink> Términos de Uso</termsOfUseLink>. Además, ha aceptado que podemos usar y divulgar su información como se establece en nuestra <privacyPolicyLink>Política de privacidad</privacyPolicyLink>',
    goodJob: '¡Buen trabajo!',
    submitAndGetBenefits:
      '¡Envíe el formulario para obtener acceso a los beneficios!',
    congratulations: 'Felicidades',
    weWillBeInTouchSoon: '¡Nos veremos pronto!',
    successThanks: 'Gracias por unirte a nosotros!',
    successMesage: 'Por favor revise su bandeja de entrada, pronto se le enviará un correo de bienvenida!',
    useBenefits: 'Usa tus Beneficios',
    contactUs: 'Contacta con nosotros',
  },
}
